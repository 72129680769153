import React from 'react'
import {
  Title,
  Text,
  Image,
  View,
  Card,
  Guide,
  Button,
  Separator,
  Stack
} from 'design-system'
import { Hero } from './Hero.jsx'
import { Footer } from './Footer.jsx'
import { css } from 'glamor'

const dotBackground = css({

  position: 'relative',

  '&:before': {
    content: ' ',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: '0',
    /* Create a repeated dotted background */
    backgroundImage: 'radial-gradient(var(--color-alt-primary) .5px, transparent 0) ',
    // background: 'var(--surface-decorated)',
    backgroundSize: '7px 7px',
    backgroundPosition: '-8.5px -8.5px',
    /* Create a radial gradient image mask to fade the dots out */
    // maskImage: 'radial-gradient(ellipse at center, transparent 75%, hsla(0, 0%, 0%, 1))',
    maskImage: 'radial-gradient(ellipse at center, hsla(0, 0%, 0%, 1), transparent 45%)'

  }

})

export const sections = [

  {
    id: "about",
    className:dotBackground,
    style: { padding: 'var(--space-xxl) var(--space-s) var(--space-l) ' },
    render: ({ about, socialProof, quickLinks }) => (
      <View layout="row-wrap" placeContent="normal space-between" gap="xl" style={{ padding: '0 var(--space-l)', backdropFilter: 'blur(2px)', borderRadius: 'var(--space-m)'}}>
        
        <View gap="l" style={{ }}>
            {
              about.columns.map(({ name, role, imgSrc, text }) => (
                <div style={{ maxWidth: 'var(--max-width-s)'}} >
                  <Image
                    key={name}
                    src={imgSrc}
                    style={{
                      borderRadius: '50%',
                      width: '100%',
                      maxWidth: '200px',
                      height: 'auto',
                      marginBottom: 'var(--space-l)',
                    }}
                  />

                  <View gap="s" style={{ marginBottom: 'var(--space-l)' }}>
                    <Title as="h1" variant="primary">{name}</Title>
                    <Title as="h2" variant="lead" style={{ color: 'var(--color-accent)'}}>{role}</Title>
                  </View>

                  <Text>
                    {text}
                  </Text>
                </div>
              ))
            }

            <Text variant="small" style={{ margin: 'var(--space-m) 0' }}>
              {socialProof.title}
            </Text>

            <View layout="row" gap="xl" style={{ overflowX: 'auto', maxWidth: '100%'  }}>
              {
                socialProof.columns.map(({ title, logo }) => (
                  <Image
                    key={title}
                    src={logo}
                    style={{ width: 'auto', height: '20px', flex: '0 0 auto'  }}
  
                  />
                ))
              }
            </View>

        </View>

        <View
          placeItems="flex-start"
          placeContent="flex-start"
          style={{ maxWidth: 'var(--max-width-m)' }}
          gap="l"
        >
          <Guide
            title={quickLinks.title}
            titleVariant="secondary"
            text={quickLinks.text}
            style={{ marginBottom: 'var(--space-s)' }}
          />
          {quickLinks.lists.map(({ title, text, list }) => (
            <View placeItems="flex-start" placeContent="flex-start">
              <Title title={title} variant="tertiary" style={{ marginBottom: 'var(--space-m)'}}>
                {title}
              </Title>
              <Text maxWidth="s">
                {text}
              </Text>
              {
                list.map(button => <Button {...button} variant="link" target="_blank"/> )
              }
            </View>
          ))}
        </View>

      </View>
    )
  },

  {
    id: "services",
    gap: "xl",
    className: dotBackground,
    // surface: "decorated",
    style: { padding: 'var(--space-xl) var(--space-m) var(--space-xxl)' },
    render: ({ services, hero }) => (
      <View gap="l" style={{ backdropFilter: 'blur(2px)' }}>
        <Hero {...hero}/>
        <View layout="row" gap="l" placeItems="flex-start" style={{ overflowX: 'auto' }}>
          {
            services.map(({ title, text }) => (
              <Card variant="shadow" key={title} style={{ minWidth: '300px', backdropFilter: 'blur(2px)' }}>
                <Title variant="secondary" style={{ marginBottom: 'var(--space-m)' }}>
                  {title}
                </Title>
                <Text style={{ maxWidth: '600px' }}>
                  {text}
                </Text>
              </Card>
            ))
          }
        </View>
      </View>
    )
  },


  // {
  //   id: "projects",
  //   style: {
  //     padding: 'var(--space-xxl) var(--space-m) var(--space-xl)',
  //     minHeight: '60vh'
  //   },
  //   render: ({ projects }) => (
  //     <>
  //       <Guide
  //         title={projects.title}
  //         titleVariant="display"
  //         text={projects.text}
  //         style={{ textAlign: 'left', maxWidth: '600px', marginBottom: 'var(--space-xl)' }}
  //       />
  //       {projects.categories.map(({ title, text, links }) => (
  //         <div style={{ marginBottom: 'var(--space-xl)'}}>
  //           <Title title={title} variant="tertiary" style={{ marginBottom: 'var(--space-l)'}}>
  //             {title}
  //           </Title>
  //           <Text>
  //             {text}
  //           </Text>
  //           <Stack>
  //             {
  //               links.map(buttons => (
  //                 <Stack horizontal style={{ marginBottom: 'var(--space-m)' }}>
  //                   {
  //                     buttons.map(button => <Button {...button} variant="link" target="_blank" style={{ marginRight: 'var(--space-m)'}}/>)
  //                   }
  //                 </Stack>
  //               ))
  //             }
  //           </Stack>
  //         </div>
  //       ))}
  //     </>
  //   )
  // },

  {
    id: "contact",
    surface: 'accent',
    maxWidth: 'l',
    style: {
      padding: 'var(--space-xl) var(--space-m)',
    },
    render: ({ contact }) => (
      <Card variant="border">
        <Footer
          columns={contact.columns}
          placeContent="center space-between"
          gap="l"
        />
      </Card>
    )
  }
]
