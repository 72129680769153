import React, { useMemo } from 'react'
import { css } from 'glamor'

export const Title = ({
  as: Element = 'h1',
  variant,
  ...props
}) => {

  const titleStyles = useMemo(() => {
    return css({
      '--max-width': `var(--title-${variant}-max-width, var(--title-default-max-width, 100%))`,
      '--color': `var(--title-${variant}-color, var(--foreground, inherit))`,
      '--font-family': `var(--title-${variant}-font-family, var(--title-default-font-family, sans-serif))`,
      '--font-size': `var(--title-${variant}-font-size, var(--title-default-font-size, 2rem))`,
      '--font-weight': `var(--title-${variant}-font-weight, var(--title-default-font-weight, 700))`,
      '--line-height': `var(--title-${variant}-line-height, var(--title-default-line-height, 1))`,
      '--text-shadow': `var(--title-${variant}-text-shadow, var(--title-default-text-shadow, none))`,
      '--letter-spacing': `var(--title-${variant}-letter-spacing, var(--title-default-letter-spacing, normal))`,

      display: 'block',
      boxSizing: 'border-box',
      margin: 0,

      color: 'var(--foreground)',
      lineHeight: 'var(--line-height)',
      fontFamily: 'var(--font-family)',
      fontSize: 'var(--font-size)',
      fontWeight: 'var(--font-weight)',
      textShadow: 'var(--text-shadow)',
      letterSpacing: 'var(--letter-spacing)'

    })
  }, [variant])

  return <Element {...props} {...titleStyles} />
}
