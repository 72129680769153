import React from 'react'
import { css } from 'glamor'
import { Title, Text, Button, Card } from 'design-system'

export const Hero = ({
  title,
  titleMaxWidth = '800px',
  text,
  actions = [],
}) => (
  <Card variant="shadow" style={{ padding: 'var(--space-xl)', backdropFilter: 'blur(2px)' }}>

    <div style={{ maxWidth: 'var(--max-width-l)', margin: '0 auto'}}>
      <Title
        variant="display"
        style={{ maxWidth: titleMaxWidth, marginBottom: 'var(--space-l)' }}
      >
        {title}
      </Title>

      <Text style={{ marginBottom: 'var(--space-l)' }}>
        {text}
      </Text>

      <div style={{ display: 'flex', gap: 'var(--space-m)'}}>
        {actions.map(({ label, ...props }) => (
          <Button {...props} key={label}>
            {label}
          </Button>
        ))}
      </div>
    </div>

  </Card>
)
