import { Standard } from './standard.js'


export const createCss = (theme, options) => {
  if (!theme) return ''
  const { space, surfaces } = theme
  // console.log('[Styles] Creating css')
  // console.log('[Styles] Proccesing spacing', surfaces)

  const spacingCss = createCssVars(space)
  // console.log('[Styles] Proccesing vars', spacingCss)

  const surfacesCss = createSurfacesCss(surfaces)
  // console.log('[Styles] Proccesing vars', surfacesCss)

  return surfacesCss
}

// createCss(Standard)

// function createCssUtilClasses(themePortion) {
//   return getCssVars(themePortion)
//     .flatMap(Object.entries)
//     .reduce((acc, [name, value]) => {
//       return acc + `${name}: ${value};\n`
//     }, '')
// }

function createCssVars(themePortion) {
  if (!themePortion) return ''
  return getCssVars(themePortion)
    .flatMap(Object.entries)
    .reduce((acc, [name, value]) => {
      return acc + `${name}: ${value};\n`
    }, '')
}

function getCssVars(styleObject, cssVars = [], _previousPath = '') {
  return Object.entries(styleObject).map(([key, value]) => {

    if (typeof value === 'object') {
      return !!_previousPath
        ? getCssVars(value, cssVars, `${_previousPath}-${key}`)
        : getCssVars(value, cssVars, `--${key}`)
    } else if (!!_previousPath) {
      return { [`${_previousPath}-${key}`]: value }
    } else {
      return { [`--${key}`]: value }
    }
  }).flat()
}


const defaultCss = `
[data-surface] {\n
  background: var(--background);
  color: var(--foreground);
  fill: var(--foreground);
}\n
`

function createSurfacesCss(surfaces) {
  if (!surfaces) return ''
  return Object.entries(surfaces)
    .reduce((css, [surfaceName, rules]) => {
      // console.log('surfaceName', surfaceName)
      // console.log('rules', rules)
      const vars = createCssVars(rules)
      return css + (
`\n
[data-surface="${surfaceName}"] {\n
  ${vars}
}\n
`
      )
    }, defaultCss)



}
