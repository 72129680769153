import React from 'react'
import { css } from 'glamor'
import { Icon } from '../icon'
import { Button } from '../button'
import { Text } from '../text'
import { Title } from '../title'
import { Card } from '../card'
import { Tags } from '../tags'

const styles = css({

  display: 'grid',
  flexDirection: 'column',
  gap: 'var(--space-m)',
  pageBreakInside: 'avoid',

  '& header': {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

  },

  '@media print': {
    boxShadow: 'none',
    marginBottom: '8px !important'
  }

})

export const ResumeExperience = ({
  title,
  subTitle,
  date,
  description,
  website,
  github,
  tags = [],
  ...props
}) =>
  <Card {...styles} {...props}>

    <header>
      <div>
        <Title as="h3" variant="secondary">{title}</Title>
        <Title as="h4" variant="lead">{subTitle}</Title>
        <Text as="span">{date}</Text>
      </div>
    </header>

    {
      (!!description || !!props.children) && (
        <Text>
          {!!description ? description : props.children}
        </Text>
      )
    }

    {
      (!!website || !!github) && (
        <div>
          {
            !!website && (
              <Button variant="link" href={website} target="_blank">
                <Icon name="Website"/>
                Visit website
              </Button>
            )
          }
          {
            !!github && (
              <Button variant="link" href={github} target="_blank">
                <Icon name="GitHub" style={{ with: '22px', height: '22px' }}/>
                GitHub
              </Button>
            )
          }
        </div>
      )
    }

    {
      !!tags && !!tags.length && <Tags tags={tags}/>
    }
  </Card>
