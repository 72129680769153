import {
  OskarsSylwan,
  Ossy,
  PlexusSanitas,
  Minimal,
  Standard
} from 'themes'

export default {
  workspaceId: '36zDqF0TKZZ5KkJdyg7NH',
  gaId: 'G-4K89R6J0G0',
  // supportedLanguages: ['sv', 'en'],
  theme: 'oskarssylwan',
  themes: {
    Light: Ossy,
    oskarssylwan: OskarsSylwan,
    Minimal: Minimal,
    'Health care': PlexusSanitas,
    standard: Standard
  }
}
