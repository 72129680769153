import React from 'react'
import { css } from 'glamor'

const styles = css({
  backgroundColor: 'hsl(0, 0%, 100%)',
  display: 'inline-block',
  borderRadius: '4px',
  boxShadow: '1px 1px 10px hsla(0, 0%, 30%, .2)',
  border: '1px solid hsl(0, 0%, 90%)',
  padding: '8px 0',
  listStyle: 'none',
  minWidth: '150px',
  '& .context-menu__item': {
    padding: '8px 16px',
    color: 'hsl(0, 0%, 30%)',
    fontFamily: 'sans-serif',
    textAlign: 'left',
    cursor: 'pointer',
  },
  '& .context-menu__item:hover ': {
    backgroundColor: 'hsl(0, 0%, 95%)'
  },
  '& .context-menu__separator': {
    height: '1px',
    margin: '8px 16px',
    backgroundColor: 'hsl(0, 0%, 95%)',
  }
})

const ContextMenuItem = ({ className = '', children, ...props}) =>
  <li className={`context-menu__item ${className}`} {...props}>
    {children}
  </li>

const ContextMenuSeparator = ({ className = '', ...props}) =>
  <li className={`context-menu__separator ${className}`} {...props}>
  </li>

export const ContextMenu = ({ children }) => (
  <ul {...styles}>
    {children}
  </ul>
)

ContextMenu.Item = ContextMenuItem
ContextMenu.Separator = ContextMenuSeparator
