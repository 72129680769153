import React from 'react'
import { Outlet } from 'react-router-dom'
import {
  Tabs,
  PageSection,
  Title,
  Text,
  Page,
  Separator,
  View,
} from 'design-system'
import { Header } from './Header.jsx'
import { Footer } from './Footer.jsx'

export const StandardLayout = ({
  logo,
  headerActions,
  headerAccentBorder = true,
  navigation,
  maxWidth = 'medium',
  copyright,
  footer,
}) => {

  return (
    <Page
      layout="page"
      maxWidth={maxWidth}
      surface="base"
      style={{
        minHeight: '100dvh',
        position: 'relative',
      }}
    >

      <PageSection
        id= "header"
        slot='header'
        surface='primary'
        surfaceAs= "header"
        maxWidth='l'
        style={{
          background: 'hsla(0, 0%, 100%, .9)',
          backdropFilter: 'saturate(180%) blur(5px)',
          width: '100%',
          top: '0',
          position: 'sticky',
          zIndex: '10',
          overflowY: 'auto',
        }}
      >
        <Header
          logo={logo}
          actions={headerActions}
          navigation={navigation}
          style={{
            padding: 'var(--space-m) var(--space-m)',
            borderRadius: '18px',
          }}
        />
      </PageSection>

      <PageSection
        id= "outlet"
        slot='content'
        surfaceAs= "main"
        maxWidth='full'
      >
        <Outlet />
      </PageSection>

      <View as="footer" slot="footer" surface="alt-primary" maxWidth="l">

        <PageSection
          id="footer-section-copyright"
          surface="alt-primary"
          maxWidth="l"
          style={{ padding: 'var(--space-xl) var(--space-m)' }}
        >
          <Footer columns={footer.columns} />
        </PageSection>

        <PageSection
          id="footer-section-copyright"
          surface="alt-secondary"
          maxWidth="l"
          style={{ padding: 'var(--space-m)', textAlign: 'center', }}
        >
          <Text variant="small">
            {copyright}
          </Text>
        </PageSection>

      </View>

    </Page>
  )
}
