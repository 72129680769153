import React from 'react'
import ReactMarkdown from 'react-markdown'
import { css } from 'glamor'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { Button, Title, Text, Card } from 'design-system'

const codeBlockStyles = css({
  margin: 'var(--space-l) 0',
  '& pre': {
    margin: '0'
  }
})

const defaultComponents = {
  'h1': ({ node, ...props }) => <Title variant="primary" {...props} style={{ marginBottom: 'var(--space-l)' }} />,
  'h2': ({ node, ...props }) => <Title variant="secondary" {...props} style={{ marginBottom: 'var(--space-m)', marginTop: 'var(--space-l)' }} />,
  'h3': ({ node, ...props }) => <Title variant="tertiary" {...props} style={{ marginBottom: 'var(--space-m)' }} />,
  'p': ({ node, ...props }) => <Text {...props} style={{ marginBottom: 'var(--space-m)' }} />,
  'ul': ({ node, ...props }) => <ul {...props} style={{ listStyle: 'none', paddingLeft: 'var(--space-m)' }} />,
  'li': ({ node, ...props }) => <li {...props} style={{ marginBottom: 'var(--space-m)' }}/>,
  'a': ({ node, ...props }) => <Button {...props} variant="link" />,
  'code': ({ node, className, ...props }) => {
    const [_, language] = /language-(\w+)/.exec(className || '') || []
    return (
      <Card variant="cover" {...codeBlockStyles}>
        <SyntaxHighlighter
          style={{
            ...atomOneLight,
            "hljs": {
              "display": "block",
              "overflowX": "auto",
              "padding": 'var(--space-l)',
              "color": "#383a42",
              "background": "#fafafa"
          }}}
          language={language}
          {...props}
        />
      </Card>
    )
  },
}

export const MarkdownViewer = ({
  markdown,
  children,
  components = {},
  ...props
}) =>
  <ReactMarkdown
    children={markdown || children}
    components={{ ...defaultComponents, ...components }}
    {...props}
  />
