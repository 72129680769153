import React from 'react'
import { css } from 'glamor'
import { Text } from '../text'

const styles = css({
  display: 'inline-flex',
  overflow: 'hidden',
  backgroundColor: 'transparent',
  height: '34px',
  transition: 'background-color .3s ease',
  '& .item': {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    cursor: 'pointer',
    fontFamily: 'sans-serif',
    fontSize: '18px',
    lineHeight: '1',
  },
  '& .divider': {
    content: '/',
    padding: '0 4px',
    color: 'var(--separator-primary)',
    display: 'flex',
    alignItems: 'center',
  },
  '& .button': {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    cursor: 'pointer',
    fontFamily: 'sans-serif',
    fontSize: '18px',
    lineHeight: '1',
    padding: '4px 8px',
    border: '1px solid transparent',
    borderRadius: '2px',
    color: 'hsl(0, 0%, 35%)',
  },
  '& .button:hover': {
    color: 'hsl(0, 0%, 20%)',
    backgroundColor: 'hsl(0, 0%, 95%)'
  },
})

export const Breadcrumbs = ({
  divider = '/',
  as: Element = 'div',
  ...props
}) =>
  <Element {...props} {...styles}>
    {
      React.Children.map(
        React.Children.toArray(props.children).filter(x => x),
        (child, i) => (
          <div className="item">
            { i !== 0 && <span className="divider">{divider}</span>}
            <Text as="span" className="button">
              {child}
            </Text>
          </div>
        )
      )
    }
  </Element>
