import React from 'react'
import './Stack.css'

export const Stack = ({
  horizontal,
  bordered,
  className = '',
  as: Element = 'div',
  ...props
}) => (
  <Element className={`
    ossy-stack
    ${horizontal ? 'ossy-stack--horizontal' : 'ossy-stack--vertical'}
    ${bordered ? 'ossy-stack--bordered' : ''}
    ${className}
  `} {...props}/>
)

export const StackItem = ({
  fill,
  as: Component = 'div',
  className = '',
  ...props
}) => (
  <Component className={`
    ossy-stack__item
    ${fill ? 'ossy-stack__item--fill' : ''}
    ${className}`
  } {...props}/>
)

Stack.Item = StackItem
