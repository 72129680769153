import React, { useMemo } from 'react'
import { css } from 'glamor'

const Default = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
}

const Row = {
  display: 'flex',
  flexDirection: 'row'
}

const RowWrap = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row'
}

const OffCenter = {
  display: 'grid',
  gridTemplateRows: '1fr max-content 2fr',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    "."
    "content"
    "."
  `,

  '& [slot="content"]': { gridArea: 'content' }
}

const VerticalTop = {
  display: 'grid',
  gridTemplateRows: '.05fr max-content 1fr',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    "."
    "content"
    "."
  `,

  '& [slot="content"]': { gridArea: 'content' }
}

const SplitEven = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr) )',
  gridTemplateRows: 'auto',
  gridAutoFlow: 'column'
}

const page = {
  height: '100%',
  width: '100%',
  maxWidth: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: 'auto 1fr auto',
  gridTemplateAreas: `
    "header header header"
    "sidebar-primary content sidebar-secondary"
    "footer footer footer"
  `,

  '& [slot="header"]': { gridArea: 'header' },
  '& [slot="content"]': { gridArea: 'content' },
  '& [slot="sidebar-primary"]': { gridArea: 'sidebar-primary' },
  '& [slot="sidebar-secondary"]': { gridArea: 'sidebar-secondary' },
  '& [slot="footer"]': { gridArea: 'footer' }
}

const sidebarLayout = {
  height: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: 'auto  1fr auto ',
  gridTemplateAreas: `
    "sidebar-primary header header"
    "sidebar-primary content sidebar-secondary"
    "sidebar-primary footer footer"
  `,

  '& [slot="header"]': { gridArea: 'header' },
  '& [slot="content"]': { gridArea: 'content' },
  '& [slot="sidebar-primary"]': { gridArea: 'sidebar-primary' },
  '& [slot="sidebar-secondary"]': { gridArea: 'sidebar-secondary' },
  '& [slot="footer"]': { gridArea: 'footer' }
}

const layouts = {
  default: Default,
  row: Row,
  'row-wrap': RowWrap,
  page: page,
  sidebar: sidebarLayout,
  'off-center': OffCenter,
  'vertical-top': VerticalTop,
  'split-even': SplitEven
}

export const View = ({
  surface,
  layout,
  placeItems = 'stretch stretch',
  placeContent = 'normal normal',
  gap = 'none',
  as: Container = 'div',
  ...props
}) => {

  const layoutStyles = useMemo(() => css({
    gap: `var(--space-${gap})`,
    placeItems: placeItems,
    placeContent: placeContent,
    minWidth: '0',
    minHeight: '0',
    maxWidth: '100%',
    // width: '100%',
    ...(layouts[layout] || Default)
  }), [layout])

  return <Container
    data-surface={surface}
    {...props}
    {...layoutStyles}
  />
}
