import React from 'react'
import { css } from 'glamor'
import { Title } from '../title'
import { Text } from '../text'
import { Button } from '../button'

const styles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
})

export const Guide = ({
  title,
  titleVariant = 'secondary',
  text,
  textVariant,
  actions = [],
  as: Element = 'div',
  children,
  align = 'center',
  style = {},
  ...props
}) => (
  <Element {...styles} {...props} style={{ textAlign: align, ...style }}>
    <div>
      <Title variant={titleVariant} style={{ marginBottom: 'var(--space-s)' }}>
        {title}
      </Title>
      {
        !!text
          ? <Text style={{ marginBottom: 'var(--space-m)' }}>{text}</Text>
          : children
      }
      <div style={{ display: 'flex', gap: 'var(--space-m)', justifyContent: 'center' }}>
        {actions.map(({ label, key, ...props }) => (
          <Button {...props} key={key | label}>
            {label}
          </Button>
        ))}
      </div>
    </div>
  </Element>
)
