import React, { useMemo } from 'react'
import { css } from 'glamor'

import { AccountCircle } from './icons/AccountCircle.jsx'
import { AddChart } from './icons/AddChart.jsx'
import { AddCircle } from './icons/AddCircle.jsx'
import { AddToQueue } from './icons/AddToQueue.jsx'
import { Call } from './icons/Call.jsx'
import { Dashboard } from './icons/Dashboard.jsx'
import { ArrowBack } from './icons/ArrowBack.jsx'
import { Cached } from './icons/Cached.jsx'
import { Delete } from './icons/Delete.jsx'
import { Directory } from './icons/Directory.jsx'
import { Document } from './icons/Document.jsx'
import { Email } from './icons/Email.jsx'
import { Equalizer } from './icons/Equalizer.jsx'
import { FilterList } from './icons/FilterList.jsx'
import { GitHub } from './icons/GitHub.jsx'
import { Grading } from './icons/Grading.jsx'
import { Home } from './icons/Home.jsx'
import { InsertPhoto } from './icons/InsertPhoto.jsx'
import { Instagram } from './icons/Instagram.jsx'
import { KeyboardBackspace } from './icons/KeyboardBackspace.jsx'
import { LinkedIn } from './icons/LinkedIn.jsx'
import { Next } from './icons/Next.jsx'
import { OpenInNew } from './icons/OpenInNew.jsx'
import { PresentToAll } from './icons/PresentToAll.jsx'
import { Previous } from './icons/Previous.jsx'
import { RestorePage } from './icons/RestorePage.jsx'
import { Store } from './icons/Store.jsx'
import { TextSnippet } from './icons/TextSnippet.jsx'
import { Twitter } from './icons/Twitter.jsx'
import { ViewAgenda } from './icons/ViewAgenda.jsx'
import { ViewColumn } from './icons/ViewColumn.jsx'
import { ViewWeek } from './icons/ViewWeek.jsx'
import { Website } from './icons/Website.jsx'

export const icons = {
  AccountCircle,
  AddChart,
  AddCircle,
  AddToQueue,
  ArrowBack,
  Cached,
  Call,
  Dashboard,
  Delete,
  Directory,
  Document,
  Email,
  Equalizer,
  FilterList,
  GitHub,
  Grading,
  Home,
  InsertPhoto,
  Instagram,
  KeyboardBackspace,
  LinkedIn,
  Next,
  OpenInNew,
  PresentToAll,
  Previous,
  RestorePage,
  Store,
  TextSnippet,
  Twitter,
  ViewAgenda,
  ViewColumn,
  ViewWeek,
  Website,
}

export const Icon = ({ name, variant, ...props }) => {

  const styles = useMemo(() => {
    return css({
      color: `var(--on-icon-${variant}, var(--on-icon-default))`,
      fill: `var(--on-icon-${variant}, var(--on-icon-default))`,
      height: `var(--icon-${variant}--size, var(--icon-default--size))`,
      width: `var(--icon-${variant}--size, var(--icon-default--size))`,
      fontSize: `var(--icon-${variant}--size, var(--icon-default--size))`,
      ':hover': {
        color: `var(--on-icon-${variant}--hover, var(--on-icon-default--hover))`,
        fill: `var(--on-icon-${variant}--hover, var(--on-icon-default--hover))`,
        cursor: `var(--icon-${variant}--cursor, var(--on-icon-default--cursor))`,
      },
      ':focus': {
        color: `var(--on-icon-${variant}--hover, var(--on-icon-default--hover))`,
        fill: `var(--on-icon-${variant}--hover, var(--on-icon-default--hover))`,
      },
      ':disabled': {
        color: `var(--on-icon-${variant}--disabled, var(--on-icon-default--disabled))`,
        fill: `var(--on-icon-${variant}--disabled, var(--on-icon-default--disabled))`,
        cursor: 'not-allowed'
      },
    })
  }, [variant])

  const Element = icons[name] || (() => <></>)
  return <Element {...props} {...styles} />
}
