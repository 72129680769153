import React, { useState, useMemo } from 'react'
import { css } from 'glamor'

const styles = css({
  position: 'relative',
  overflow: 'hidden',

  '& .placeholder, & .source': {
    objectFit: 'cover',
    objectPosition: 'center center',
    transition: 'opacity .3s ease, filter .3s ease',
    lineHeight: '1',
    display: 'block'
  },

  '& .placeholder': {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    height: '100%',
    filter: 'blur(10)',
    opacity: '1'
  },

  '& .source': {
    filter: 'blur(10)',
    opacity: '0',
    width: '100%',
    height: '100%'
  },

  '& [data-loaded="true"].source': {
    opacity: '1',
    filter: 'blur(0)',
  },

  '& [data-loaded="true"].placeholder': {
    opacity: '0',
    filter: 'blur(0)',
  }

})

export const Image = ({
  placeholderSrc,
  src,
  alt,
  style,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <div {...styles} style={style}>

      { !placeholderSrc ? null :
          <img data-loaded={loaded} className="placeholder" src={placeholderSrc} alt={alt}  />
      }

      <img
        className="source"
        data-loaded={loaded}
        src={src}
        alt={alt}
        {...props}
        onLoad={() => setLoaded(true)}
      />

    </div>
  )
}
