import React, { useMemo } from 'react'
import { css } from 'glamor'
import { Title, Button, Text, Layout } from 'design-system'


export const Footer = ({
  placeContent = 'normal space-between',
  gap = 'xl',
  columns = [],
}) => {

  const styles = useMemo(() => css({
    padding: 'var(--space-s)',

    '& .title': {
      marginBottom: 'var(--space-m)'
    },

    '& .list': {
      padding: '0'
    },

    '& .list-item': {
      listStyle: 'none'
    }
  })
  ,[columns])

  return (
    <Layout variant="row" placeContent={placeContent} gap={gap} {...styles} >

      {
        columns.map(column => (
          <div style={{ maxWidth: `var(--max-width-${column.maxWidth}, 100%)` }}>
            <Title as="h4" variant="tertiary" className="title">
              {column.title}
            </Title>
            {
              !!column.text && <Text>{column.text}</Text>
            }
            {
              !!column.list && (
                <ul className="list" key={column.title}>
                  {
                    column.list.map(({ label, ...anchorProps }) => (
                      <li className="list-item" key={label}>
                        <Button variant="link" {...anchorProps} style={{ marginBottom: 'var(--space-s)' }}>
                          {label}
                        </Button>
                      </li>
                    ))
                  }
                </ul>
              )
            }
          </div>
        ))
      }
    </Layout>
  )
}
