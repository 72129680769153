import React from 'react'
import { View } from '../view'
import { css } from 'glamor'

const layoutStyles = css({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  height: '100%',
})

export const PageSection = ({
  surface,
  maxWidth,
  surfaceAs = 'section',
  layout,
  placeItems,
  placeContent,
  gap,
  children,
  style = {},
  ...props
}) => {

  return (
    <View
      surface={surface}
      as={surfaceAs}
      style={{ width: '100%', ...style }}
      {...props}
    >
      <View
        layout={layout}
        placeItems={placeItems}
        placeContent={placeContent}
        gap={gap}
        {...layoutStyles}
        style={{ maxWidth: `var(--max-width-${maxWidth}, 100%)` }}
      >
        {children}
      </View>
    </View>
  )
}
