import React from 'react'
import 'react-dom'
import { createRoot } from 'react-dom/client'
import { App } from 'connected-components'
import appSettings from './config.js'
import routes from './routes.jsx'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App {...appSettings} routes={routes} />)
