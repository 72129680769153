import React from 'react'
import './asDropZone.css'

// TODO: remove the need for className prop
// it causses ennying issues
export const asDropZone = Component => ({
  dropEffect = 'move',
  onDragOver = () => {},
  onDragEnter = () => {},
  onDragLeave = () => {},
  onDrop = () => {},
  format = 'text/plain',
  ...restProps
}) => {

  const _onDragOver = event => {
    event.preventDefault()
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = dropEffect
    onDragOver(event)
  }

  const _onDragEnter = event => {
    event.preventDefault()
    event.target.classList.add('dropzone--dragover')
    onDragEnter(event)
  }

  const _onDragLeave = event => {
    event.preventDefault()
    event.target.classList.remove('dropzone--dragover')
    onDragLeave(event)
  }

  const _onDrop = event => {
    event.preventDefault()
    event.target.classList.remove('dropzone--dragover')
    onDrop(event.dataTransfer.getData(format))
  }

  return <Component
    onDragOver={_onDragOver}
    onDragEnter={_onDragEnter}
    onDragLeave={_onDragLeave}
    onDrop={_onDrop}
    {...restProps}
  />
}
