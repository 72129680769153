import { Standard } from './standard.js'
import { topography } from './patterns/index.js'

export const OskarsSylwan = {
  ...Standard,
  // space: Standard.space,
  // color: Standard.color,
  // 'max-width': Standard['max-width'],
  //
  // title: Standard.title,
  // text: Standard.text,
  // button: Standard.button,
  // card: Standard.card,
  // separator: Standard.separator,
  // surface: Standard.surface,
  // surfaces: Standard.surfaces,
  // resume: Standard.resume,
}
