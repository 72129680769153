import React from 'react'
import { css } from 'glamor'
import { Tabs, Button, Title } from 'design-system'

const styles = css({
  height: '100%',
  width: '100%',
  margin: 0,

  boxSizing: 'border-box',
  display: 'flex',
  // flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',

  '& [data-slot="logo"]': {
    order: '1',
    display: 'flex',
    justifyContent: 'center',
  },

  '& [data-slot="navigation"]': {
    order: '2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  '& [data-slot="actions"]': {
    order: '3',
    display: 'flex',
    flexShrink: '0',
    flexGrow: '1',
    justifyContent: 'flex-end',
    gap: '8px'
  },

  '@media (max-width: 800px)': {

    '& [data-slot="logo"]': {
      justifyContent: 'flex-start',
    },

    '& [data-slot="actions"]': {
      order: '1',
    },
  }

})

export const Header = ({
  logo,
  actions = [],
  navigation = [],
  ...props
}) => {

  return (
    <div {...styles} {...props}>

      <div data-slot="logo">
        { typeof logo !== 'string'
          ? logo
          : (
            <Title variant="logo">
              {logo}
            </Title>
        )}
      </div>

      <Tabs
        gap="m"
        data-slot="navigation"
        tabs={navigation}
        variant="neutral"
      />

      <div data-slot="actions">
        {actions.map(x => <Button {...x} /> )}
      </div>

    </div>
  )
}
