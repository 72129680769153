import React, { useMemo } from 'react'
import { css } from 'glamor'

export const Text = ({
  as: Element = 'p',
  variant,
  maxWidth = 'full',
  text,
  children,
  ...props
}) => {

  const textStyles = useMemo(() => {
    return css({
      '--max-width': `var(--text-${variant}-max-width, var(--text-default-max-width, 100%))`,
      '--color': `var(--text-${variant}-color, var(--foreground, inherit))`,
      '--font-family': `var(--text-${variant}-font-family, var(--text-default-font-family, sans-serif))`,
      '--font-size': `var(--text-${variant}-font-size, var(--text-default-font-size, 2rem))`,
      '--font-weight': `var(--text-${variant}-font-weight, var(--text-default-font-weight, 400))`,
      '--line-height': `var(--text-${variant}-line-height, var(--text-default-line-height, 1.6))`,
      '--margin-bottom': `var(--text-${variant}-margin-bottom, var(--text-default-margin-bottom, 1rem))`,
      '--letter-spacing': `var(--text-${variant}-letter-spacing, var(--text-default-letter-spacing))`,

      maxWidth: `var(--max-width-${maxWidth})`,

      display: 'block',
      boxSizing: 'border-box',
      overflow: 'hidden',

      color: 'var(--color)',
      fontFamily: 'var(--font-family)',
      fontSize: 'var(--font-size)',
      fontWeight: 'var(--font-weight)',
      lineHeight: 'var(--line-height)',
      letterSpacing: 'var(--letter-spacing)',

      margin: 0,
      marginBottom: 'var(--margin-bottom)',

      ':last-of-type': {
        marginBottom: '0'
      }

    })
  }, [variant])

  return <Element children={children || text} {...props} {...textStyles} />
}
