import React from 'react'
import { redirect, useParams, useNavigate } from 'react-router-dom'
import { Resume as ConnectedResume } from 'connected-components'
import { useTheme, Page } from 'design-system'
import { ThemeDisplay } from 'design-system-extras'
import { Swedish as SE } from './data.js'
import { Logo } from './Logo.jsx'
import { StandardLayout } from './StandardLayout.jsx'
import { sections } from './LandingPage.jsx'
import { Standard } from 'themes'

const navigation = [
  { id: 'start-nav', label: 'Start', href: '/' },
  { id: 'features-nav', label: 'Tjänster', href: '#services' },
  { id: 'contact-nav', label: 'Kontakt', href: '#contact' },
  { id: 'references-nav', label: 'Om oss', href: '#about-us' }
]

const headerActions = [
  {
    key: 'resume',
    children: 'Online CV',
    href: '/sv/resume',
    variant: 'cta',
    target: '_blank'
  }
]

const Resume = () => {
  const { languageCode = 'en' } = useParams()
  const navigate = useNavigate()
  const { activeTheme, themes, setTheme } = useTheme()
  const path = `/${languageCode}/Consultants/Oskars Sylwan/`

  const actions = [
    {
      variant: 'neutral',
      label: `Theme: ${activeTheme}`,
      contextMenu: themes.map(themeName => ({
        label: themeName,
        onClick: () => setTheme(themeName)
      }))
    },
    {
      variant: 'neutral',
      label: languageCode.toUpperCase(),
      contextMenu: [
        { label: 'EN', onClick: () => navigate('/en/resume') },
        { label: 'SV', onClick: () => navigate('/sv/resume') },
      ]
    },
    {
      variant: 'cta',
      label: 'View in Google Drive',
      target: '_blank',
      href: languageCode === 'sv'
        ? 'https://docs.google.com/document/d/1JyjzYMRFkXZT8_mgybinQAJHNNlfzFgHcrQsUnq4knk/edit?usp=share_link'
        : 'https://docs.google.com/document/d/1N6jWLjghIhNOGSspS8YUBhH2pykkieijbB1IVWb606g/edit?usp=share_link'
    }
  ]

  return <ConnectedResume path={path} actions={actions} />
}

export default [
  {
    path: '/',
    element: <StandardLayout surface="linear-gradient" maxWidth="l" {...SE.StandardLayout } />,
    children: [
      {
        index: true,
        loader: () => redirect('/sv'),
      },
      {
        path: '/sv',
        element: <Page maxWidth="l" sections={sections} {...SE.LandingPage} />
      },
      {
        path: '/en',
        element: <Page sections={sections} {...SE.LandingPage} />
      },
      {
        path: '/sv/design-system',
        element: <ThemeDisplay theme={Standard} />
      },
    ]
  },

  {
    path: '/:languageCode/resume',
    element: <Resume />
  },
  {
    path: '*',
    loader: () => redirect('/en/resume')
  }
]