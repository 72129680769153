import React from 'react'
import { Resume as _Resume } from 'design-system-extras'
import { useResume } from './useResume.js'

export const Resume = ({
  path,
  ...props
}) => {
  const resumeProps = useResume(path)
  return resumeProps.status === 'Success'
    ? <_Resume {...resumeProps} profileCardVariant="resume" {...props}/>
    : <></>
}
