import React from 'react'
import { css } from 'glamor'
import { Title, Text, Button } from 'design-system'

const heroStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  '@media (min-width: 820px)': {
    // minHeight: '60vh',
    flex: '0 0 auto',
  }
})

export const Hero = ({
  title,
  titleMaxWidth = '800px',
  text,
  actions = [],
}) => (
  <div {...heroStyles}>

    <Title
      variant="display"
      style={{ maxWidth: titleMaxWidth, marginBottom: 'var(--space-l)' }}
    >
      {title}
    </Title>

    <Text style={{ marginBottom: 'var(--space-l)' }}>
      {text}
    </Text>

    <div style={{ display: 'flex', gap: 'var(--space-m)'}}>
      {actions.map(({ label, ...props }) => (
        <Button {...props} key={label}>
          {label}
        </Button>
      ))}
    </div>

  </div>
)
