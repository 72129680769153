import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import {
  createBrowserRouter,
  createMemoryRouter,
  RouterProvider,
  useNavigate,
  Outlet
} from 'react-router-dom'
import { WorkspaceProvider } from '@ossy/cms-client-react'
import { Theme } from 'design-system'

const AppLayout = ({
  workspaceId,
  theme,
  themes,
  gaId,
  apiUrl
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!gaId) return
    ReactGA.initialize(gaId)
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [gaId])

  const onNavigate = e => {
    if (e.target.tagName !== 'A') return

    const href = e.target.getAttribute('href')
    const hasTarget = e.target.hasAttribute('target')
    const isLocal = (href || '').startsWith('#')
    const isExternalLink = hasTarget || (href || '').includes('.')

    if (!href) return
    if (isExternalLink || isLocal ) return

    event.preventDefault()
    if (gaId) ReactGA.send({ hitType: 'pageview', page: href })
    navigate(href)
  }

  return (
    <Theme theme={theme} themes={themes}>
      <WorkspaceProvider workspaceId={workspaceId} _apiUrl={apiUrl}>
        <div onClick={onNavigate} style={{ display: 'contents' }}>
          <Outlet />
        </div>
      </WorkspaceProvider>
    </Theme>
  )
}

export const App = ({
  workspaceId,
  theme,
  themes,
  routes = [],
  gaId,
  apiUrl
}) => {

  const appRoutes = [
    {
      path: '/',
      element: (
        <AppLayout
          gaId={gaId}
          theme={theme}
          themes={themes}
          workspaceId={workspaceId}
          apiUrl={apiUrl}
        />
      ),
      children: routes.map(x => {
        if (x.path !== '/') return x
        if (x.path === '/' && !!x.children) return x
        return { ...x, index: true }
      })
    }
  ]

  const createRouter = global.__IS_STORYBOOK__
    ? createMemoryRouter
    : createBrowserRouter

  return (
    <RouterProvider router={createRouter(appRoutes)} />
  )
}
